import React, { ReactElement, useState } from "react";
import { formatTimestampToDate } from "../../../../lib/date";
import { ListItem } from "../../../Data Display/List/List";
import { OverviewListItem } from "./OverviewListItem/OverviewListItem";
import { RueckfrageHeader } from "../../../../api/types";
import { getRueckfrageLink } from "../../../../lib/rueckfragen/getRueckfrageLink";
import { useTranslateFunction } from "../../../../hooks/useTranslate";
import { useNavigate } from "react-router-dom";

interface OverviewListItemProps {
  rueckfrageHeader: RueckfrageHeader;
  lastAnswerDate: number | undefined;
  disabled: boolean;
  unread: boolean;
  index: number;
  overviewItemsLength: number;
}

const OverviewListItemWrapper = (props: OverviewListItemProps): ReactElement => {
  const { rueckfrageHeader, lastAnswerDate, disabled, unread, index, overviewItemsLength } = props;
  const [showTooltip, setShowTooltip] = useState(false);
  const translate = useTranslateFunction();
  const navigate = useNavigate();

  const onKeyDownHandler = (e: React.KeyboardEvent): void => {
    if (e.key === "Enter") {
      goToRueckFrageIfNotDisabled();
      showTooltipWhenDisabled();
    } else if (e.key === "Escape") {
      hideTooltipWhenDisabled();
    }
  };

  const onClickHandler = (): void => {
    goToRueckFrageIfNotDisabled();
    showTooltipWhenDisabled();
  };

  const goToRueckFrageIfNotDisabled = (): void => {
    !disabled && goToRueckfrage();
  };

  const goToRueckfrage = (): void => {
    const rueckfrageLink: string | undefined = getRueckfrageLink(rueckfrageHeader);
    const encodedLink: string = btoa(rueckfrageLink!);

    navigate(`/detail/${encodedLink}`);
  };

  const showTooltipWhenDisabled = (): void => {
    disabled && setShowTooltip(true);
  };

  const hideTooltipWhenDisabled = (): void => {
    disabled && setShowTooltip(false);
  };

  return (
    <ListItem
      data-cy={`overview-rueckfrage-${rueckfrageHeader.nachrichtHeader.id}-${index}`}
      key={`rueckfrage-${rueckfrageHeader.nachrichtHeader.id}`}
      tabIndex={0}
      $unread={unread}
      $disabled={disabled}
      $roundedBorderTop={index === 0}
      $roundedBorderBottom={index === overviewItemsLength - 1}
      onKeyDown={onKeyDownHandler}
      onClick={onClickHandler}
      onFocus={showTooltipWhenDisabled}
      onMouseOver={showTooltipWhenDisabled}
      onBlur={hideTooltipWhenDisabled}
      onMouseLeave={hideTooltipWhenDisabled}
    >
      <OverviewListItem
        title={translate("overviewPage.listItem.title", {
          formattedDate: formatTimestampToDate(rueckfrageHeader.nachrichtHeader.sendAt)
        })}
        answeredAt={
          lastAnswerDate
            ? translate("overviewPage.listItem.answeredAt", {
                formattedDate: formatTimestampToDate(lastAnswerDate)
              })
            : undefined
        }
        subtitle={rueckfrageHeader.title}
        trustLevel={rueckfrageHeader.trustLevel}
        actionButtonLabel={translate("common.open")}
        id={rueckfrageHeader.id}
        disabled={disabled}
        unread={unread}
        onClickHandler={onClickHandler}
        onKeyDownHandler={onKeyDownHandler}
        showTooltip={showTooltip}
      />
    </ListItem>
  );
};

export default OverviewListItemWrapper;
