import styled from "styled-components";
import { Heading } from "../../components/Data Display/Heading/Heading";
import { Text } from "../../components/Data Display/Text/Text";

export const StyledDiv = styled.div`
  gap: 4px;
`;

export const StyledHeading = styled(Heading)`
  line-height: 1.75rem;
  height: 28px;
`;

export const StyledText = styled(Text)`
  line-height: 1.75rem;
  height: 28px;
`;

export const NoMessagesContainer = styled.div`
  display: flex;
  padding: 80px 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
`;

export const NoMessagesText = styled(Text)`
  text-align: center;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
`;
