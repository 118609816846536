import React, { ReactElement } from "react";
import { Button } from "../../Buttons/Button/Button";
import { changeLocale, selectLocale } from "../../../features/localisation/localeSlice";
import { useAppDispatch, useAppSelector } from "../../../hooks";

const LocaleButton = (): ReactElement => {
  const dispatch = useAppDispatch();
  const locale = useAppSelector(selectLocale);

  return (
    <Button
      data-testid={`locale-change-btn`}
      onClick={() => {
        dispatch(changeLocale(locale === "de-DE" ? "en-EN" : "de-DE"));
        // accessibility
        document.documentElement.lang = locale === "de-DE" ? "en" : "de";
      }}
    >
      {locale.toUpperCase().substring(0, 2)}
    </Button>
  );
};

export default LocaleButton;
