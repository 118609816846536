import styled from "styled-components";
import { COLORS } from "../../../../../constants";

export const TooltipContainer = styled.div<{ showTooltip: boolean }>`
  visibility: ${(props) => !props.showTooltip && "hidden"};
  width: 100%;
  position: absolute;
  top: -60px;
  left: 0;
  white-space: pre-line;
`;

export const Tooltip = styled.div`
  display: flex;
  z-index: 1;
  width: 380px;
  margin: auto;
  padding: 0.625rem;
  background-color: ${COLORS.WHITE};
  border-radius: 8px;
  box-shadow: 1px 1px 1px 1px ${COLORS.LIGHT_GREY};
  color: black;

  svg {
    margin-right: 8px;
    width: 36px;
    height: 18px;
    color: ${COLORS.SLATE};
  }
`;

export const TooltipText = styled.div`
  font-size: 1rem;
  line-height: 1.5rem;

  p {
    margin: 0;
  }

  p:first-child {
    margin-bottom: 4px;
  }
`;
