import React, { ReactElement } from "react";
import { getLastAnswerDate } from "../../../lib/rueckfragen/getLastAnswerDate";
import { isRueckfrageHeaderMarkedNew } from "../../../lib/rueckfragen/isRueckfrageMarkedNew";
import { List } from "../../Data Display/List/List";
import { useAppSelector } from "../../../hooks";
import { sortRueckfrageHeaders } from "../../../lib/rueckfragen/sortRueckfrageHeaders";
import { RueckfrageHeader } from "../../../api/types";
import OverviewListItemWrapper from "./OverviewListItemWrapper/OverviewListItemWrapper";

const OverviewList = (): ReactElement => {
  const { rueckfrageHeaders }: { rueckfrageHeaders: RueckfrageHeader[] } = useAppSelector((state) => state.rueckfragen);

  return (
    <List data-cy={`overview-rueckfrage-list`}>
      {[...rueckfrageHeaders].sort(sortRueckfrageHeaders).map((rueckfrageHeader: RueckfrageHeader, index: number) => (
        <OverviewListItemWrapper
          key={`rueckfrage-${rueckfrageHeader.nachrichtHeader.id}`}
          rueckfrageHeader={rueckfrageHeader}
          lastAnswerDate={getLastAnswerDate(rueckfrageHeader)}
          disabled={!rueckfrageHeader.accessible}
          unread={isRueckfrageHeaderMarkedNew(rueckfrageHeader)}
          index={index}
          overviewItemsLength={rueckfrageHeaders.length}
        />
      ))}
    </List>
  );
};

export default OverviewList;
