import React, { ReactElement } from "react";
import { MdLock } from "react-icons/md";
import * as S from "./OverviewListItemTooltipStyles";
import { TrustLevel } from "../../../../../../api/types";
import { useTranslateFunction } from "../../../../../../hooks/useTranslate";

const tooltipTrustlevelToTextMap: { [key: string]: any } = {
  HIGH: {
    line1: "overviewPage.toolTipText.trustLevelHigh.line1",
    line2: "overviewPage.toolTipText.trustLevelHigh.line2"
  },
  SUBSTANTIAL: {
    line1: "overviewPage.toolTipText.trustLevelSubstantial.line1",
    line2: "overviewPage.toolTipText.trustLevelSubstantial.line2"
  }
};

interface OverviewListItemTooltipProps {
  showTooltip: boolean;
  trustLevel: TrustLevel;
}

const OverviewListItemTooltip = ({ showTooltip, trustLevel }: OverviewListItemTooltipProps): ReactElement => {
  const translate = useTranslateFunction();

  return (
    <S.TooltipContainer showTooltip={showTooltip} data-testId={`overview-rueckfrage-tooltip`}>
      <S.Tooltip>
        <MdLock aria-label={translate("overviewPage.listItem.lockedIconAriaLabel")} />
        <S.TooltipText>
          <p>{translate(tooltipTrustlevelToTextMap[trustLevel].line1)}</p>
          <p>{translate(tooltipTrustlevelToTextMap[trustLevel].line2)}</p>
        </S.TooltipText>
      </S.Tooltip>
    </S.TooltipContainer>
  );
};

export default OverviewListItemTooltip;
